// _app.js

import React from 'react';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import '../css/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css'; 

config.autoAddCss = false;
function MyApp({ Component, pageProps }) {
  return (
    <HelmetProvider> {/* Wrap your app with HelmetProvider */}
      <Component {...pageProps} />
    </HelmetProvider>
  );
}

export default MyApp;
